#number {
    text-align: center !important;
    color: var(--color-primary-60) !important;
    font-weight: 900 !important;
    padding-bottom: 0px;
    font-size: 20px;
    border-bottom: 2px solid var(--color-primary-60);
}
#numberWithBigFont {
    text-align: center !important;
    color: var(--color-primary-60) !important;
    font-weight: 900 !important;
    font-size: 40px;
    padding-bottom: 0px;
    border-bottom: 2px solid var(--color-primary-60);
}
#tableModal{
 text-align-last: center;
}
